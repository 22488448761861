import React from 'react'

import json from '../assets/data.json'

class DataJson extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <div>
          <br /><span>{json['title-1']} ------> {json['info-1']}</span>
      </div>
    )
  }
}

export default DataJson
